.content{
    // width: 652px;
    // height: 430px;
    flex: 1;
    border-radius: 0px 0px 20px 20px;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // padding-bottom: 16px;
    box-sizing: border-box;
    
    .promptTxt {
        margin-top: 16px;
        font-family: Source Han Sans;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FF2626;
        line-height: 20px;
        .icon {
            font-size: 16px;
            margin-right: 3px;
            line-height: 1;
        }
    }
    .goods{
        padding: 16px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        gap: 12px;
    }
    .item{
        cursor: pointer;
        flex: 1;
        height: 196px;
        border-radius: 12px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        background: #FDF5F0;
        border: 1px solid transparent;
        
        .newTip{
            position: absolute;
            left: 0;
            top: 0;
            // width: 76px;
            height: 20px;
            border-radius: 11px 0px 11px 0px;
            padding: 1px 8px;
            box-sizing: border-box;
            background: linear-gradient(117deg, #FE581A 2%, #FE7B3F 81%), linear-gradient(117deg, #FE581A 2%, #FE7B3F 81%);
            font-family: Weimob Increase;
            font-size: 12px;
            line-height: 18px;
            color: #FFFFFF;
        }
        .goodName{
            margin-top: 39px;
            font-family: MiSans;
            font-size: 14px;
            font-weight: 500;
            line-height: 26px;
            text-align: center;
            letter-spacing: 0px;
            color: #626973;
        }

        .originPrice{
            font-size: 12px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: 0px;
            text-decoration: line-through;
            color: #B6BABF;
                
        }
        .bottom{
            position: absolute;
            left: 3px;
            right: 3px;
            bottom: 3px;
            height:31px;
            line-height: 31px;
            text-align: center;
            background: #FFFFFF;
            border-radius: 10px;
            font-size: 14px;
            color: #1E2226;
        }
        // &:hover {
        //     // outline: 1px solid #D53DFF;
        //     background-image: linear-gradient(#FFEEE8, #FFEEE8), linear-gradient(153deg, #FB3DAD -1%, #FF873C 84%);
        //     background-origin: border-box;
        //     background-clip: content-box, border-box;
        // }
        &:not(.active) {
            &:hover {
                background: #F9EDE5;
            }
        }
        &.withPrompt {
            height: 160px;
            .goodName {
                margin-top: 28px;
            }
            .price {
                margin: 8px 0 10px;
            }
            &.active {
                .price {
                    margin: 5px 0;
                }
            }
        }
    }
    .active{
        background: linear-gradient(148deg, #FFF9EB 5%, #FFEDC3 91%);
        box-sizing: border-box;
        border: 1px solid #F4DDA6;
        
        .goodName {
            font-size: 18px;
            font-weight: 500;
            line-height: 18px;
            color: #450F1A;
            margin-top: 43px;
        }
        
        .bottom{
            // left: 0;
            background: linear-gradient(111deg, #FF6D3D 3%, #FF4A02 80%);
            // bottom: 0;
            // right: 0;
            // border-radius: 0 0 11px 11px;
            color: #FFFFFF;
            font-weight: 600;
            .bottomText{
                background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(93deg, #1E2226 -2%, #FF5E3E -2%, #FF47CE 107%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }
        }
        .price{
            font-family: klein Font;
            font-size: 52px;
            font-weight: 500;
            line-height: 51.48px;
            color: #450F1A;
            margin-bottom: 4px;
            margin-top: 11px;
            .priceSign {
                font-family: PingFang SC;
                font-size: 24px;
                font-weight: 500;
                color: #450F1A;
                width: 21px;
                margin-top: 16px;
                display: flex;
                justify-content: flex-start;
            }
        }
        .bottomTips {
            background: #F9DFA4;
            color: #450F1A;
        }
        .newTip {
            top: -1px;
            left: -1px;
        }
    }
    .activityItem{
        .newTip{
            background: linear-gradient(121deg, #FE581A 0%, #FE7B3F 83%), linear-gradient(121deg, #FE581A 0%, #FE7B3F 83%);
        }
        .bottom{
            font-weight: 600;
        }
        .bottomText{
            background: #FD3F00;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    }
    &>.des{
        margin: 0 16px;
        font-size: 12px;
        line-height: 22px;
        color: #8A9099;
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        padding-bottom: 16px;
        .tipIcon{
            font-size: 14px !important;
            color: #8A9099;
            margin-left: 4px;
            cursor: pointer;
        }
    }
    .notAllowBuyText {
        font-family: Source Han Sans;
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        color: #626973;
        height: 164px;
        justify-content: center;
        span {
            font-weight: 700;
            font-size: 14px;    
            color: #1E2226; 
            margin: 0 2px;
        }
    }
    .functionListBox {
        height: 164px;
        padding: 24px 16px 13px;
        box-sizing: border-box;
        .title {
            font-family: Source Han Sans;
            font-size: 16px;
            line-height: 16px;
            text-align: center;
            color: #1E2226;
            margin-bottom: 26px;
        }
        &.bagFunctionListBox {
            padding-top: 20px;
            .title {
                margin-bottom: 20px;
            }
        }
        .functionList {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .functionItem {
                display: flex;
                flex-direction: column;
                align-items: center;
                .icon {
                    width: 40px;
                    height: 40px;
                    margin-bottom: 8px;
                }
                .text {
                    font-family: Source Han Sans;
                    font-size: 14px;
                    color: #1E2226;
                }
                .desc {
                    font-family: PingFang SC;
                    font-size: 12px;
                    color: #626973;
                    opacity: 0.6;
                }
            }
            &.bagFunctionList {
                padding: 0 26px;
            }
        }
    }
    .paycontainer{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .payCode{
        width: 256px;
        height: 100%;
        border-radius: 12px;
        // margin-top: 16px;
        // margin-left: 16px;
        // padding: 16px;
        box-sizing: border-box;
        border-radius: 10px;
        position: absolute;
        right: -256px;
        top: 0;
        
        .payCodeBox {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            padding: 20px 0;
            box-sizing: border-box;
            
            .topContent {
                display: flex;
                flex-direction: column;
                align-items: center;
                
                .paySHowAmount {
                    font-family: klein Font;
                    font-size: 56px;
                    font-weight: 500;
                    color: #FD3F00;
                    span {
                        font-size: 24px;
                        text-shadow: 0px 1px 14px rgba(79, 122, 253, 0.0001);
                    }
                }
                
                .payOriginalAmount {
                    font-family: Source Han Sans;
                    font-size: 14px;
                    line-height: 14px;
                    text-decoration: line-through;
                    color: #B58C47;
                    margin-top: -16px;
                }
                
                .payDiscountAmount {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;
                    border-radius: 4px;
                    background: linear-gradient(116deg, #FE581A 2%, #FE7B3F 81%);
                    width: 83px;
                    height: 21px;
                    font-family: Source Han Sans;
                    font-size: 12px;
                    font-weight: bold;
                    color: #FFFFFF;
                    text-shadow: 0px 1px 14px rgba(79, 122, 253, 0.0001);
                    span {
                        margin-left: 9px;
                    }
                }
                
                .codeBox {
                    width: 172px;
                    height: 172px;
                    box-sizing: border-box;
                    position: relative;
                    padding: 7px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 26px;
                    
                    &::after {
                        content: '';
                        border-radius: 12px;
                        padding: 3px; /* 边框宽度 */
                        box-sizing: border-box;
                        background: linear-gradient(139deg, #FF9392 1%, #FFC187 95%);
                        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
                        mask-composite: exclude;
                        width: 100%;
                        height: 100%;
                        left: 0; 
                        top: 0;
                        position: absolute;
                    }
                    
                    .imgContainer {
                        width: 100%;
                        height: 100%;
                        background: #fff;
                        border-radius: 10px;
                        padding: 4px;
                        box-sizing: border-box;
                        overflow: hidden;
                        
                        img {
                            width: 100%;
                            height: 100%;
                        }
                        .goMemberTab, .goPersonalCenter {
                            width: 116px;
                            height: 40px;
                            border-radius: 40px;
                            background: linear-gradient(124deg, #FE581A 0%, #FE7B3F 84%);
                            font-family: PingFang SC;
                            font-size: 16px;
                            color: #FFFFFF;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            
                            .icon {
                                font-size: 16px;
                                margin-left: 4px;
                            }
                        }
                        .goPersonalCenter {
                            border-radius: 40px;
                            background: rgba(114, 61, 40, 0.1);
                            backdrop-filter: blur(10px);
                            color: #1E2226;
                        }
                    }
                }
                .payMethods {
                    display: flex;
                    align-items: center;
                    font-family: PingFang SC;
                    font-size: 14px;
                    line-height: 22px;
                    color: #333333;
                    margin-top: 15px;
                    
                    img {
                        width: 24px;
                        height: 24px;
                        margin-right: 4px;
                    }
                }
            }
    
            .xy {
                font-family: PingFang SC;
                font-size: 12px;
                font-weight: normal;
                line-height: 20px;
                text-align: center;
                color: #626973;
                span {
                    color: #626973;
                }
            }
        }
        
        .payWay{
            width: 102px;
            height: 38px;
            box-sizing: border-box;
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 14px;
            line-height: 22px;
            color: #1E2226;
            &:hover{
                background: #E9ECF0;
            }
                
        }
        .activePay{
            color: #844A20;
            background: #EE8351;
            font-weight: 600;
            color: #FFFFFF;
            &:hover{
                background: #EE8351;
            }

        }
        .code{
            width: 152px;
            height: 152px;
            box-sizing: border-box;
            border: 2px solid transparent;
            border-radius: 10px;
            background-image: linear-gradient(#FFFFFF, #FFFFFF),
            linear-gradient(135deg, #E24E4C -8%, #FF7547 103%);
            background-origin: border-box;
            background-clip: content-box, border-box;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 12px;
            margin-right: 16px;
            img{
                width: 132px;
                height: 132px;
            }
            :global{
                .ant-spin-nested-loading .ant-spin-blur{
                    opacity: 0.7;
                    &::after{
                        opacity: 0.7;
        
                    }
                }
                .ant-spin-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
        
            }
                  
        }
        .paydes{
            display: flex;
            align-items: center;
            margin-top: 13px;
            font-size: 14px;
            line-height: 22px;
            color: #333333;
            img{
                width: 24px;
                height: 24px;
                margin-right: 4px;
            }
        }
        .xy{
            margin-top: 31px;
            font-size: 14px;
            line-height: 24px;
            color: #1E2226 ;
            span{
                color: #FD3F00;
                cursor: pointer;
            }
            
            
        }
    }
    .goToMember{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .tip{
            font-size: 14px;
            line-height: 22px;
            font-weight: 400; 
            color: #1E2226;
         span{
            font-weight: 600;
         }
            
        }
        .action{
            margin-top: 17px;
            font-size: 14px;
            line-height: 22px;
            color: #722AFF;
            cursor: pointer;
        }
    }
    .price{
        position: relative;
        margin-top: 15px;
        display: flex;
        align-items: center;
        font-family: klein Font;
        font-size: 36px;
        font-weight: 500;
        line-height: 36px;
        color: #1E2226;
        margin-bottom: 12px;
        .tiyan{
            font-family: PingFang SC;
            font-size: 12px;
            line-height: 20px;
            color: #1E2226;
            font-weight: 400;
            margin-top: 9px;
        }
        .firstMonth{
            position: absolute;
            width: 28px;
            height: 14px;
            border-radius: 4px 4px 4px 0px;
            padding: 0px 4px;
            box-sizing: border-box;
            background: #EC7435;
            font-family: Inter;
            font-size: 10px;
            line-height: 14px;
            color: #FFFFFF;
            right: -27px;
            top: -9px;

        }
        .priceSign{
            width: 16px;
            height: 50%;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: right;
            letter-spacing: 0px;
            text-shadow: 0px 1px 14px rgba(79, 122, 253, 0.0001);
            margin-top: 4px;
        }

    }
    .payInfo{
        display: flex;
        align-items: center;
        flex-direction: column;
        
        .price{
            color: #FD3F00;
            .priceSign{
                margin-top: 10px;

            }
        }
    }
    .amountContent, .originalAmount {
        font-family: MiSans;
        font-size: 12px;
        font-weight: normal;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0px;
        color: #8A9099;
    }
    .originalAmount {
        text-decoration: line-through;
    }
    .bottomTips {
        position: absolute;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #F1E4DB;
        font-family: MiSans;
        font-size: 12px;
        color: #8A9099;
        height: 28px;
        width: 100%;
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
    }
}

.payResult{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #1E2226;
    height: 430px;

    img{
        width: 88px;
        height: 88px;
    }
    .result{
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 16px;
    }
    .des{
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0px;
        color: #8A9099;
        span{
            font-weight: 600;
        }
        
    }
}
.imgContainer{
    position: relative;
    height: 132px;
    width: 132px;
    .codeMask{
        position: absolute;
        top:0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.95);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        line-height: 24px;
        color: #3D3D3D;
        border-radius: 12px;
        z-index: 2;
        
        .update{
            width: 22px;
            height: 22px;
            margin-bottom: 12px;
        }
    }
}
.qrLoading{
    color: #722AFF !important;
    span {
        margin: -15px !important;
    }
}
.cutDown{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    .bottomText{
        line-height: 18px;
        font-weight: 400 !important;
    }
    :global{
        .ant-statistic{
            width: 84px !important;
            display: flex;
            align-items: center;
            
        }
        .ant-statistic-content{
            height: 100%;
            display: flex;
            align-items: center
        }
        .ant-statistic-content-value{
            font-family: klein Font;
            color: #FD3F00;
            letter-spacing: 1px;
            font-size: 16px !important;
           font-weight: 600 !important;
           line-height: 18px;
        }

    }

}
.active{
    .cutDown{
        :global{
            .ant-statistic-content-value{
                // color: #FFFFFF !important;
                background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(93deg, #1E2226 -2%, #FF5E3E -2%, #FF47CE 107%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
            }
        }

    }
}
.tipPopContainer{
    .popTip{
        width: 436px;
        font-size: 14px;
        line-height: 22px;
        color: #626973;

    }
    :global{
        .ant-popover-inner{
            padding: 8px 12px;
        }
        .ant-popover-arrow{
            margin-right: -12px;
        }
        .ant-popover-content{
            margin-right: -12px;
        }

    }
}

.ac {
    display: flex;
    align-items: center;
}

.failPart, .successPart {
    display: flex;
    align-items: center;
    
    .icon {
        font-size: 24px;
        color: #FF5050;
        margin-right: 6px;
        line-height: 1;
    }
    .result {
        font-family: PingFang SC;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0px;
        color: #1E2226; 
        margin: 0;
    }
    .desc {
        font-family: PingFang SC;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0px;
        color: #1E2226;
        margin-top: 8px;
    }
}
.successPart {
    .icon {
        color: #5FD562;
    }
    .des1 {
        font-family: PingFang SC;
        font-weight: 400;
        font-size: 14px;
        margin-top: 8px;
        line-height: 22px;
        span {
            font-weight: 600;
        }
    }
    .des2 {
        font-family: PingFang SC;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0px;
        color: #8A9099;
        margin-top: 4px;
    }
}
.scanner {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 40px;
    // background: linear-gradient(rgb(138 43 226 / 0%) 0%, rgb(114 42 255 / 10%) 99.4%, #722AFF 99.4%, #722AFF 100%);
    background: url('https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/erweimasaodong@3x.png') no-repeat;
    background-size: 100% 100%;
    animation: 6s cubic-bezier(0.53, 0.01, 0.58, 1) 0s infinite normal none running scan, 6s step-end 0s infinite normal none running flip;
}

@keyframes scan {
    0%, 100% {
      top: -40px;
    }
    50% {
      top: 100%;
    }
  }
  
  @keyframes flip {
    0%, 49.99% {
      transform: scaleY(1);
    }
    50%, 100% {
      transform: scaleY(-1);
    }
  }