.payContainer{
    // display: flex;
    // width: 972px;
    // height: 542px;
    box-sizing: border-box;
    background: radial-gradient(93% 147% at -2% -4%, #FFE3AA 13%, #FFF4DE 79%, #FFF9F0 100%);
    position: relative;
    background-image: url(https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/danchuangbeijing@2x.jpg);
    background-size: 100% 100%;
    padding: 17px 0 20px 24px;
    .bg1{
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 0;
        width: 216px;
        height: 229px;
    }
    .bg2{
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        width: 960px;
        height: 574px;

    }
}
.payModal{
    top:calc(50% - 299px) !important;

    :global{
        .ant-modal-body {
            padding: 0;
            border-radius: 36px;
            overflow: hidden;

          }
          .ant-modal-content{
            padding: 0;
            border-radius: 36px;
          }
    }

}
.close {
    position: absolute;
    top: -16px;
    right: -36px;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    background-color: rgba(255, 255, 255, 0.3);
    display: flex;
    cursor: pointer;
  
    img {
      width: 15px;
      height: 15px;
      margin: auto;
    }
    &:hover{
        background-color: rgba(255, 255, 255, 0.6);

    }
  }
.topcontainer{
    // width: 288px;
    // height: 100%;
    // z-index: 1;
    // box-sizing: border-box;
    // padding-left: 30px;
    // padding-top: 30px;
    margin-bottom: 13px;
    .header{
        height: auto;
        display: flex;
        align-items: center;
        img{
            width: 40px;
            height: 40px;
            margin-right: 12px;
            border-radius: 100px;
            border: 1px solid #fff;
        }
        .name{
            font-family: PingFang SC;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0px;
            color: #1E2226;
        }
        .phone{
            font-family: PingFang SC;
            font-size: 12px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: 0px;
            color: #626973;
        }
    }
}
.rightContainer{
    z-index: 1;
    display: flex;
    align-items: center;


    .payContent{
        width: 652px;
        // height: 482px; 
        display: flex;
        flex-direction: column;
        position: relative;      
             
        .payTab,.rechargePayTab{
            display: flex;
            cursor: pointer;
            .memberTitle{
                position: relative;
             //    color: #8066FF;
                font-weight: 500;
                .tip{
                 position: absolute;
                 top:-14px;
                 font-family: Weimob Increase;
                 left: 66px;
                 width: 64px;
                 height: 20px;
                 border-radius: 12px 12px 12px 0px;
                 border: 1px solid #FFFFFF;
                 background: linear-gradient(121deg, #FF3DA8 0%, #FF5FEA 83%);
                 padding: 1px 7px;
                 font-size: 12px;
                 line-height: 18px;
                 display: flex;
                 align-items: center;
                 box-sizing: border-box;
                 color: #FFFFFF;
                }
                &:hover {
                 // color: #722AFF!important;
                }
             }
            .memberTab{                    
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0px;
                display: flex;
                align-items: center;
                justify-content: center;

                
                    
            }
            .rechargeTab{
                .memberTitle{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .tip{
                     left: 49px;
                     background: linear-gradient(121deg, #FE581A 0%, #FE7B3F 83%), linear-gradient(121deg, #F44A80 0%, #EE2929 83%), linear-gradient(121deg, #FF3DA8 0%, #FF5FEA 83%);
                    }
                    &:hover {
                     // color: #722AFF!important;
                    }
                 }
                font-size: 16px;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        .payTab{
            .memberTab{
                width: 341px;
                height: 52px;
                background: url('https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/bg_tab_xuanzhong.png');    
                background-size: 100% 52px;
                z-index: 1;
                color: #1E2226;
                .memberTitle {
                    font-size: 18px;
                }
                // cursor:default;
            }
            .rechargeTab{
                width: 335px;
                height: 46px;
                margin-top: 6px;
                border-top-right-radius: 20px;
                background: #FFF8E6;
                box-shadow: inset -2px 2px 2px 0px rgba(255, 255, 255, 0.8);
                margin-left: -24px;
                color:  #8C6D30;
                &:hover {
                //  color: #722AFF!important;
                }
            }
        }
        .rechargePayTab{
            .rechargeTab{
                width: 341px;
                height: 52px;
                background: url('https://cdn2.weimob.com/saas/saas-fe-sirius-orion-node/production/zh-CN/483/bg_tab_xuanzhong02.png');    
                background-size: 100% 52px;
                z-index: 1;
                color: #1E2226!important;
                margin-left: -24px;
                // cursor:default;
            }
            .memberTab{
                width: 335px;
                height: 46px;
                // color: #9C7142;
                margin-top: 6px;
                border-top-left-radius: 20px;
                background: #FFF8E6;
                box-shadow: inset -2px 2px 2px 0px rgba(255, 255, 255, 0.8);
                .memberTitle {
                    color: #8C6D30;
                }

                // &:hover{
                //     color: #8066FF;
                // }
            }
        }

    }


  }

  .payWrapper{
    :global{
        .ant-modal-mask{
          background-color: rgba(0, 0, 0, .5);
          backdrop-filter: blur(8px);
          z-index: 1050;
        }
        .ant-modal-wrap{
          z-index: 1050;
        }
    }
  }



  .logo {
    width: 105px;
    display: block;
  }
  .rechargeCutDown{
    font-family: MiSans;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #626973;
    display: flex;
    align-items: center;
    height: 18px;
    :global{
        .ant-statistic{
            width: 95px;
            height: 100%;
            background: transparent;
            .ant-statistic-content{
                font-family: MiSans;
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                font-family: klein Font;
                color: #626973;
                letter-spacing: 2px;
            }
            .ant-statistic-content-value{

            }
        }
    }
  }
  .leftImg{
    margin-top: 71px;
    width: 100%;
    object-fit: contain;
  }