.customerServiceBox {
    padding: 0 16px;
    box-sizing: border-box;
    border-radius: 12px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    width: 256px;
    position: absolute;
    top: 0;
    right: -256px;
    height: 100%;

    .qrCode {
        width: 172px;
        height: 172px;
    }

    .desc {
        font-family: PingFang SC;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0px;
        color: #1E2226;
        margin-top: 14px;
        text-align: center;
    }
}